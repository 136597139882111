import { createContext } from '@lit/context'

export interface IPreviewContext {
  updatePreview(preview: unknown): void
}

const contextKey = Symbol('one-ux-preview-context')

export const defaultPreviewContext = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updatePreview(preview: unknown) {}
} satisfies IPreviewContext

export const previewContext = createContext<IPreviewContext>(contextKey)
