import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    /* background-color: rgba(255, 0, 0, 0.15) !important; */

    --one-ux-tab--color: var(--one-ux-palette--brand-neutral-500);
    --one-ux-tab--font: var(--one-ux-font--heading-200);
    --one-ux-tab--background-color: transparent;
    --one-ux-tab--box-shadow: none;
    --one-ux-tab--border: 1px solid var(--one-ux-palette--brand-neutral-200);
    --one-ux-tab--border-radius: var(--one-ux-radius--400) var(--one-ux-radius--400) 0 0;
    --one-ux-tab--indent: 6px 16px 8px;
    --one-ux-tab--icon-size: var(--one-ux-size--icon-200);

    --one-ux-tab--selected-indicator-color: transparent;
    --one-ux-tab--selected-text-color: var(--one-ux-palette--brand-core-500);
    --one-ux-tab--selected-indicator-border-radius: var(--one-ux-radius--300) var(--one-ux-radius--300) 0 0;
    --one-ux-tab--selected-indicator-height: 4px;
    --one-ux-tab--selected-indicator-indent: var(--one-ux-spacing--300);
  }

  [role='tab'] {
    background: var(--one-ux-tab--background-color);
    color: var(--one-ux-tab--color);
    font: var(--one-ux-tab--font);
    box-shadow: var(--one-ux-tab--box-shadow);
    border-left: var(--one-ux-tab--border);
    border-right: var(--one-ux-tab--border);
    padding: var(--one-ux-tab--indent);
    border-radius: var(--one-ux-tab--border-radius);
    cursor: pointer;

    display: flex;
    gap: var(--one-ux-spacing--100);
    align-items: center;

    position: relative;

    &::after {
      content: '';
      position: absolute;
      background: var(--one-ux-tab--selected-indicator-color);
      height: var(--one-ux-tab--selected-indicator-height);
      border-radius: var(--one-ux-tab--selected-indicator-border-radius);
      left: var(--one-ux-tab--selected-indicator-indent);
      right: var(--one-ux-tab--selected-indicator-indent);
      bottom: 0;
    }

    &::before {
      /* TODO: This is needed atm to make tabs with icons to align horizontally when they contain icon in start slot */
      content: '';
    }

    &[aria-selected='true'],
    &:hover {
      --one-ux-tab--background-color: var(--one-ux-palette--gray-100);
      --one-ux-tab--color: var(--one-ux-tab--selected-text-color);
      --one-ux-tab--selected-indicator-color: var(--one-ux-palette--brand-core-400);
    }

    &[aria-selected='true'] {
      --one-ux-tab--box-shadow: var(--one-ux-shadow--drop-200);
      --one-ux-tab--border: 1px solid transparent;
    }

    &[aria-disabled='true'] {
      --one-ux-tab--color: var(--one-ux-palette--gray-400);

      pointer-events: none;
    }

    &:active {
      --one-ux-tab--background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);
      --one-ux-tab--border: 1px solid transparent;

      &[aria-selected='false'] {
        --one-ux-tab--selected-indicator-height: 0 !important;
      }
    }

    /**
     * Implicit
     */
    &.is-implicit {
      --one-ux-tab--background-color: transparent;
      --one-ux-tab--box-shadow: none;
      --one-ux-tab--border: none;
      --one-ux-tab--border-radius: var(--one-ux-radius--400);
      --one-ux-tab--selected-indicator-border-radius: var(--one-ux-radius--300);
      --one-ux-tab--selected-indicator-indent: 0;

      --one-ux-tab--selected-indicator-color: var(--one-ux-palette--brand-neutral-200);
      --one-ux-tab--selected-indicator-height: 1px;

      &[aria-selected='true'],
      &:hover {
        --one-ux-tab--background-color: var(--one-ux-palette--gray-100);
        --one-ux-tab--color: var(--one-ux-tab--selected-text-color);
        --one-ux-tab--selected-indicator-color: var(--one-ux-palette--brand-core-400);
        --one-ux-tab--selected-indicator-height: 4px;
      }

      &[aria-selected='true'],
      &[aria-selected='true']:hover {
        --one-ux-tab--selected-indicator-indent: 0;
      }

      &:hover {
        --one-ux-tab--selected-indicator-indent: 4px;
      }

      &:active {
        --one-ux-tab--background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);

        &[aria-selected='false'] {
          --one-ux-tab--selected-indicator-height: 0;
        }
      }
    }

    /**
     * Weights
     */
    &.is-weight-high {
      --one-ux-tab--indent: 11px 24px;
    }

    &.is-weight-low {
      --one-ux-tab--font: var(--one-ux-font--heading-100);
      --one-ux-tab--indent: 4px 12px 6px;
      --one-ux-tab--icon-size: var(--one-ux-size--icon-100);

      &[aria-selected='true'],
      &:hover {
        --one-ux-tab--selected-text-color: var(--one-ux-palette--brand-neutral-600);
        --one-ux-tab--selected-indicator-color: var(--one-ux-palette--brand-neutral-400);
        --one-ux-tab--selected-indicator-height: 2px;
      }
    }

    /**
     * Slotted
     */
    ::slotted(one-ux-icon) {
      font-size: var(--one-ux-tab--icon-size);
    }

    slot[name='adornment'] {
      &::slotted(one-ux-pill:not([number])),
      &::slotted(one-ux-pill[number='']) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`
