import { createContext } from '@lit/context'

export interface IPopoutContext {
  closePopout(): void
}

const contextKey = Symbol('one-ux-popout-context')

export const defaultPopoutContext = {
  closePopout() {}
} satisfies IPopoutContext

export const popoutContext = createContext<IPopoutContext>(contextKey)
