import { LitElement, css, html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { InternalElementStateChangedEvent } from '../../events/internal/InternalElementStateChangedEvent.js'
import { isBooleanAttribute, isIgnoredAttributeForContextAndAdapter } from '../../utils/attributes.js'

function notifyInternalStateChange(target: OneUxAdapterElement, name: string, value: unknown) {
  if (isIgnoredAttributeForContextAndAdapter(name)) {
    return
  }

  target.dispatchEvent(
    new InternalElementStateChangedEvent({
      property: name,
      value: isBooleanAttribute(name) ? value != null : value
    })
  )
}

const observer = new MutationObserver((mutations) => {
  for (const mutation of mutations) {
    const { attributeName, target } = mutation
    const name = attributeName as string
    const $adapter = target as OneUxAdapterElement
    notifyInternalStateChange($adapter, name, $adapter.getAttribute(name))
  }
})

/**
 * An adapter component that can be used in place of other components when doing custom implementations.
 */
@customElement('one-ux-adapter')
export class OneUxAdapterElement extends LitElement {
  static styles = css`
    :host {
      display: contents !important;
    }
  `
  constructor() {
    super()
    observer.observe(this, {
      attributes: true
    })
  }

  firstUpdated() {
    for (const { name, value } of this.attributes) {
      notifyInternalStateChange(this, name, value)
    }
  }

  render() {
    return html`<slot></slot>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-adapter': OneUxAdapterElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-adapter': OneUxAdapterElement
    }
  }
}
