import { PropertyValueMap, html } from 'lit'
import { customElement, query } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { live } from 'lit/directives/live.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Focusable } from '../../mixins/Focusable.js'
import { Implicit } from '../../mixins/Implicit.js'
import { PurposeFactory } from '../../mixins/Purpose.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { InternalValueChangedEvent } from '../../events/internal/InternalValueChangedEvent.js'
import { FormAssociatedFactory } from '../../mixins/FormAssociated.js'
import { IValue, ValueFactory } from '../../mixins/Value.js'
import { Checked, IChecked } from '../../mixins/Checked.js'
import { consume } from '@lit/context'
import { labelContext, defaultLabelContext } from '../../contexts/LabelContext.js'

const Styled = StyledFactory(style)

type valueType = unknown

const Value = ValueFactory<valueType, IValue<unknown> & IChecked>({
  getter: function () {
    if (this.checked) {
      return this.internalValue
    }
    return null
  }
})

const FormAssociated = FormAssociatedFactory<valueType, IChecked>({
  reset: function () {
    this.checked = this.initialChecked
  }
})

const Purpose = PurposeFactory({ purposes: ['default', 'main', 'caution', 'notice'] })

const BaseClass = FormAssociated(Value(Checked(Purpose(Disabled(Implicit(Focusable(Styled(OneUxElement))))))))

/**
 * A switch component to be used for user input
 */
@customElement('one-ux-switch')
export class OneUxSwitchElement extends BaseClass {
  @consume({ context: labelContext, subscribe: true })
  labelContext = defaultLabelContext

  protected willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    const hasChecked = _changedProperties.has('checked')
    const hasValue = _changedProperties.has('value')

    if (hasChecked || hasValue) {
      this.dispatchEvent(new InternalValueChangedEvent())
    }
  }

  render() {
    const label = this.labelContext.label || undefined

    return html`
      <input
        type="checkbox"
        role="switch"
        .checked=${live(this.checked)}
        .disabled=${this.disabled}
        aria-label=${ifDefined(label)}
        @input=${this.#handleInput}
      />
    `
  }

  @query('input')
  accessor _switchElement!: HTMLInputElement

  click() {
    if (this._switchElement) {
      this._switchElement.click()
    }
  }
  #handleInput = (event: Event) => {
    const $target = event.target as HTMLInputElement
    event.stopPropagation()
    const beforeInputEvent = new InputEvent('beforeinput', {
      bubbles: true,
      composed: true,
      cancelable: true,
      data: $target.checked as never as string
    })
    if (this.dispatchEvent(beforeInputEvent)) {
      this.checked = !this.checked

      this.dispatchEvent(new Event('input'))
      this.dispatchEvent(new Event('change'))
    } else {
      this.requestUpdate()
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-switch': OneUxSwitchElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-switch': OneUxSwitchElement
    }
  }
}
