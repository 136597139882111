import { ReactiveController } from 'lit'
import { OneUxPopoutElement } from './OneUxPopoutElement.js'
import { getCursorPosition } from '../../utils/mouse-helper.js'
import { clamp } from '../../visualizations/common/math.js'

type DOMRectExtra = DOMRect & {
  originalLeft: number
  originalTop: number
  originalWidth: number
  originalHeight: number
}

export class PlacementController implements ReactiveController {
  private animateRequest = 0
  private prevTop = -Infinity
  private prevLeft = -Infinity
  private referenceCursorOffsetX = -1
  private referenceCursorOffsetY = -1

  constructor(private $element: OneUxPopoutElement) {
    this.$element.addController(this)
  }

  hostConnected() {
    this.$element.toggleAttribute('state-initial', true)
    this.$element.setAttribute('popover', 'manual')
    this.$element.showPopover()
    this.animate()
  }

  hostDisconnected() {
    cancelAnimationFrame(this.animateRequest as number)
    this.referenceCursorOffsetX = -1
    this.referenceCursorOffsetY = -1
    this.$element.hidePopover()
  }

  private animate = () => {
    this.animateRequest = requestAnimationFrame(() => {
      this.$element.toggleAttribute('state-initial', false)

      const $element = this.$element
      let $referenceElement: Element | null

      if ($element.reference instanceof Element) {
        $referenceElement = $element.reference
      } else if ($element.reference === 'previous') {
        $referenceElement = $element.previousElementSibling
      } else {
        let depth = $element.referenceDepth
        $referenceElement = $element
        while (depth > 0) {
          $referenceElement =
            $referenceElement.parentElement || (($referenceElement.getRootNode() as ShadowRoot).host as HTMLElement)
          --depth
        }
      }

      if ($referenceElement) {
        const referenceRect = $referenceElement.getBoundingClientRect().toJSON() as DOMRectExtra

        if ($element.alignment === 'cursor') {
          if (this.referenceCursorOffsetX === -1 || this.referenceCursorOffsetY === -1) {
            const { cursorX, cursorY } = getCursorPosition()
            this.referenceCursorOffsetX = clamp(cursorX - referenceRect.left, 0, referenceRect.width)
            this.referenceCursorOffsetY = clamp(cursorY - referenceRect.top, 0, referenceRect.height)
          }
          if ($element.direction === 'horizontal') {
            Object.assign(referenceRect, {
              originalTop: referenceRect.top,
              top: referenceRect.top + this.referenceCursorOffsetY,
              originalHeight: referenceRect.height,
              height: 0
            })
          } else {
            Object.assign(referenceRect, {
              originalLeft: referenceRect.left,
              left: referenceRect.left + this.referenceCursorOffsetX,
              originalWidth: referenceRect.width,
              width: 0
            })
          }
        }

        let { top, left } = this.getPositions(referenceRect)
        top = Math.floor(top)
        left = Math.floor(left)

        if (top !== this.prevTop && Math.abs(top - this.prevTop) > 1) {
          $element.style.top = top + 'px'
          this.prevTop = top
        }
        if (left !== this.prevLeft && Math.abs(left - this.prevLeft) > 1) {
          $element.style.left = left + 'px'
          this.prevLeft = left
        }
      }

      this.animate()
    })
  }

  private getPositions = (referenceRect: DOMRectExtra): Position => {
    const $element = this.$element
    const elementWidth = $element.offsetWidth
    const elementHeight = $element.offsetHeight
    const viewportWidth = document.documentElement.clientWidth
    const viewportHeight = document.documentElement.clientHeight

    const checkOverflows = (top: number, left: number) => ({
      top: top < 0,
      bottom: top + elementHeight > viewportHeight,
      left: left < 0,
      right: left + elementWidth > viewportWidth
    })

    const isNotOverflowing = (overflows: { top: boolean; bottom: boolean; left: boolean; right: boolean }) => {
      return !overflows.top && !overflows.bottom && !overflows.left && !overflows.right
    }

    const getTopPosition = ({ placement, alignment }: PositioningOptions): number => {
      let offset = 0
      if (this.$element.direction === 'vertical') {
        offset = placement === 'before' ? -this.$element.offsetReference : this.$element.offsetReference
      } else if (this.$element.direction === 'horizontal') {
        offset = alignment === 'end' ? -this.$element.offsetAlignment : this.$element.offsetAlignment
      }

      if ($element.direction === 'vertical') {
        if (placement === 'before') {
          return referenceRect.top - elementHeight + offset
        } else if (placement === 'center') {
          return referenceRect.top + referenceRect.height / 2 - elementHeight / 2
        } else {
          return referenceRect.bottom + offset
        }
      } else {
        if (alignment === 'start') {
          return referenceRect.top + offset
        } else if (alignment === 'center') {
          return referenceRect.top + referenceRect.height / 2 - elementHeight / 2
        } else {
          return referenceRect.bottom - elementHeight + offset
        }
      }
    }

    const getLeftPosition = ({ placement, alignment }: PositioningOptions): number => {
      let offset = 0
      if (this.$element.direction === 'horizontal') {
        offset = placement === 'before' ? -this.$element.offsetReference : this.$element.offsetReference
      } else if (this.$element.direction === 'vertical') {
        offset = alignment === 'end' ? -this.$element.offsetAlignment : this.$element.offsetAlignment
      }

      if ($element.direction === 'horizontal') {
        if (placement === 'before') {
          return referenceRect.left - elementWidth + offset
        } else if (placement === 'center') {
          return referenceRect.left + referenceRect.width / 2 - elementWidth / 2
        } else {
          return referenceRect.right + offset
        }
      } else {
        if (alignment === 'start') {
          return referenceRect.left + offset
        } else if (alignment === 'center') {
          return referenceRect.left + referenceRect.width / 2 - elementWidth / 2
        } else {
          return referenceRect.right - elementWidth + offset
        }
      }
    }

    // Stage 1 attempt to position popout at configured alignment and placement.
    const positioning: PositioningOptions = {
      placement: $element.placement,
      alignment: $element.alignment === 'cursor' ? 'center' : $element.alignment
    }
    let top = getTopPosition(positioning)
    let left = getLeftPosition(positioning)
    let overflows = checkOverflows(top, left)
    if (isNotOverflowing(overflows)) {
      return {
        top,
        left
      }
    }
    // Stage 1 over, element could not be placed without overflow.

    // Stage 2 depending on settings different things will happen
    if ($element.alignment === 'cursor') {
      // Stage 2a, when aligning to the cursor attempt to flip and/or nudge the position so that the popout does not overflow.
      const space = 4
      if ($element.direction === 'horizontal') {
        if (overflows.top) {
          top = Math.max(top, Math.min(0, referenceRect.bottom)) + space
        }
        if (overflows.bottom) {
          const bottom = Math.min(top + elementHeight, Math.max(viewportHeight, referenceRect.originalTop))
          top = bottom - elementHeight - space
        }
        if ($element.placement === 'after' && overflows.right) {
          positioning.placement = 'before'
        }
        if ($element.placement === 'before' && overflows.left) {
          positioning.placement = 'after'
        }
        left = getLeftPosition(positioning)
      } else {
        if (overflows.left) {
          left = Math.max(left, Math.min(0, referenceRect.right)) + space
        }
        if (overflows.right) {
          const right = Math.min(left + elementWidth, Math.max(viewportWidth, referenceRect.originalLeft))
          left = right - elementWidth - space
        }
        if ($element.placement === 'after' && overflows.bottom) {
          positioning.placement = 'before'
        }
        if ($element.placement === 'before' && overflows.top) {
          positioning.placement = 'after'
        }
        top = getTopPosition(positioning)
      }

      overflows = checkOverflows(top, left)
      if (isNotOverflowing(overflows)) {
        return {
          top,
          left
        }
      }
    } else {
      // Stage 2b, for other alignments attempt to flip the popout.
      if ($element.direction === 'horizontal') {
        if (($element.alignment === 'end' || $element.alignment === 'center') && overflows.top) {
          positioning.alignment = 'start'
        }
        if (($element.alignment === 'start' || $element.alignment === 'center') && overflows.bottom) {
          positioning.alignment = 'end'
        }
        if ($element.placement === 'after' && overflows.right) {
          positioning.placement = 'before'
        }
        if ($element.placement === 'before' && overflows.left) {
          positioning.placement = 'after'
        }
      } else {
        if (($element.alignment === 'end' || $element.alignment === 'center') && overflows.left) {
          positioning.alignment = 'start'
        }
        if (($element.alignment === 'start' || $element.alignment === 'center') && overflows.right) {
          positioning.alignment = 'end'
        }
        if ($element.placement === 'after' && overflows.bottom) {
          positioning.placement = 'before'
        }
        if ($element.placement === 'before' && overflows.top) {
          positioning.placement = 'after'
        }
      }

      top = getTopPosition(positioning)
      left = getLeftPosition(positioning)
      overflows = checkOverflows(top, left)
      if (isNotOverflowing(overflows)) {
        return {
          top,
          left
        }
      }
    }
    // Stage 2 over, could not nudge (cursor alignment) and/or flip without overflow.

    // Stage 3, attempt to center on top of reference element if allowed.
    if (!$element.preventOverlap) {
      if (
        ($element.direction === 'horizontal' && (overflows.left || overflows.right)) ||
        ($element.direction === 'vertical' && (overflows.top || overflows.bottom))
      ) {
        positioning.placement = 'center'
        top = getTopPosition(positioning)
        left = getLeftPosition(positioning)
      }
    }

    // Regardless of overflow status we always return the result of the last attempt.
    // This means that overflow is still possible in the end.
    return {
      top,
      left
    }
  }
}

type PositioningOptions = {
  placement: 'before' | 'after' | 'center'
  alignment: 'start' | 'center' | 'end'
}

type Position = {
  top: number
  left: number
}
