import { html, nothing } from 'lit'
import { customElement } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Label } from '../../mixins/Label.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Focusable } from '../../mixins/Focusable.js'
import { SlotController } from '../../controllers/SlotController.js'
import { consume } from '@lit/context'
import { classMap } from 'lit/directives/class-map.js'
import { defaultTabsContext, tabsContext } from '../../contexts/TabsContext.js'
import { ifDefined } from 'lit/directives/if-defined.js'

const Styled = StyledFactory(style)

const BaseClass = Disabled(Focusable(Label(Styled(OneUxElement))))

@customElement('one-ux-tab')
export class OneUxTabElement extends BaseClass {
  @consume({ context: tabsContext, subscribe: true })
  tabsContext = defaultTabsContext

  #slots = new SlotController(this, {
    defaultSlot: true,
    slots: ['start', 'end']
  })

  guardedRender() {
    const isTooltipActive = this.#slots.hasSingleDefaultSlot('one-ux-icon')

    return html`<div
      role="tab"
      class=${classMap({
        'one-ux-element--root': true,
        'is-implicit': this.tabsContext.implicit,
        [`is-weight-${this.tabsContext.weight}`]: this.tabsContext.weight !== 'normal'
      })}
      aria-label=${this.label}
      aria-disabled=${this.tabsContext.disabled}
      aria-selected=${this.tabsContext.selected}
      one-ux-tooltip=${ifDefined(isTooltipActive ? this.label : undefined)}
      ?one-ux-tooltip-custom-aria=${isTooltipActive}
    >
      <slot name="start"></slot>
      <slot></slot>
      ${this.#slots.hasDefaultSlot() ? nothing : html`<span>${this.label}</span>`}
      <slot name="end"></slot>
      <slot name="adornment"></slot>
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tab': OneUxTabElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tab': OneUxTabElement
    }
  }
}
