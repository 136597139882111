const langCodes = ['en', 'sv', 'nb', 'fi', 'da'] as const

export type langCode = (typeof langCodes)[number]

export type LanguageSets<LangSet extends Record<string, string | Record<string, string>>> = Record<langCode, LangSet>

export const getLangCode = ($el: Element) => {
  const code = $el.closest('[lang]')?.getAttribute('lang')?.toLowerCase()?.split('-')[0]?.replace('no', 'nb') || ''
  if (langCodes.some((x) => x === code)) {
    return code as langCode
  }
  return 'en'
}
