import { createContext } from '@lit/context'
import { weight } from '../mixins/Weight.js'

export interface ITabsContext {
  weight: weight
  implicit: boolean
  selected: boolean
  disabled: boolean
}

const contextKey = Symbol('one-ux-tabs-context')

export const defaultTabsContext: ITabsContext = {
  weight: 'normal',
  implicit: false,
  selected: false,
  disabled: false
}

export const tabsContext = createContext<ITabsContext>(contextKey)
