import { html } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { ItemIcon } from '../types.js'
import paletteKeys from '../../../generated/json/palette/palette-keys.json'

export function Icon(icon?: ItemIcon) {
  if (!icon) {
    return null
  }
  return html`<one-ux-icon
    class="icon"
    set=${icon.set || 'default'}
    icon=${icon.name}
    size="300"
    style=${ifDefined(
      icon.color
        ? `color: ${paletteKeys.includes(icon.color) ? `var(--one-ux-palette--${icon.color})` : icon.color};`
        : undefined
    )}
  ></one-ux-icon>`
}
