import { PropertyValues, html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { Focusable } from '../../mixins/Focusable.js'
import { Explicit } from '../../mixins/Explicit.js'
import { FormAssociated } from '../../mixins/FormAssociated.js'
import { OneUxElement } from '../../OneUxElement.js'
import { ref, createRef } from 'lit/directives/ref.js'
import { scrollElementIntoView, keyCodes } from '../../utils.js'
import { defer } from '../../utils/function-utils.js'
import { ID_ACTIVE_OPTION } from './constants.js'
import { listOption, listOptions } from './types.js'
import { OneUxScrollElement } from '../../elements/one-ux-scroll/OneUxScrollElement.js'
import { Option } from './fragments/Option.js'
import { Group } from './fragments/Group.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { style } from './style.js'
import { type IValue, ValueFactory } from '../../mixins/Value.js'
import { Disabled } from '../../mixins/Disabled.js'
import { type IRequired, Required } from '../../mixins/Required.js'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../../mixins/Validated.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { getLanguage } from './language.js'
import { OptionContent } from './fragments/OptionContent.js'
import { ContextConsumer, consume } from '@lit/context'
import { defaultPopoutContext, popoutContext } from '../../contexts/PopoutContext.js'
import { defaultPreviewContext, previewContext } from '../../contexts/PreviewContext.js'
import { defaultLabelContext, labelContext } from '../../contexts/LabelContext.js'

const Style = StyledFactory(style)

const Value = ValueFactory<unknown[]>({
  defaultValue() {
    return []
  },
  converter(value) {
    try {
      const candidate = JSON.parse(value ?? '')
      if (Array.isArray(candidate)) {
        return candidate
      }
      return []
    } catch {
      return []
    }
  },
  isEmpty(value) {
    if (Array.isArray(value)) {
      return !value.length
    }
    return true
  }
})

const Validated = ValidatedFactory<IValue<unknown> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldYouHaveToMakeChoice } = getFormValidationLanguage(this)
    const valid = !this.empty
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldYouHaveToMakeChoice]
    }
  }
})

const BaseClass = FormAssociated(Validated(Required(Disabled(Value(Focusable(Explicit(Style(OneUxElement))))))))

/**
 * A list component that allows for single and multi select.
 *
 * @property {unknown[]} value
 * The value, or values if `multiple` is set, of the current selection.
 * Note that the type of the value is significant. This can lead to a gotcha where
 * an option is loosely (`==`) equal to a value `5 == "5"` but not strictly equal (`===`).
 *
 * This effect can be exaggerated when using the attribute API, i.e. writing HTML or JS using `setAttribute`,
 * since attributes are strings.
 *
 * In HTML `<one-ux-list options='"[｛ "value": 5, text: "not selected" ｝]"' value="['5']">` will not
 * set the value of the list dropdown as you might initially expect since the type of the option is `number` but
 * the type of the value is `string`.
 *
 * These type of gotchas do not affect VDom libraries that prioritize properties over attributes, like Vue.
 */
@customElement('one-ux-list')
export class OneUxListElement extends BaseClass {
  @consume({ context: previewContext, subscribe: true })
  private _previewContext = defaultPreviewContext

  private _popoutContext = defaultPopoutContext

  @consume({ context: labelContext, subscribe: true })
  private _labelContext = defaultLabelContext

  /**
   * The options to show. A list of options, can also optionally contain grouped options.
   */
  @property({ type: Array })
  public accessor options = [] as listOptions

  /**
   * Enables multiselect.
   */
  @property({ type: Boolean })
  public accessor multiple = false

  constructor() {
    super()
    this.addEventListener('keydown', this.#handleKeydown)
    new ContextConsumer(this, {
      context: popoutContext,
      subscribe: true,
      callback: (context) => {
        this._popoutContext = context
        this.#updateActiveIndex()
      }
    })
  }

  @state()
  private accessor _activeIndex = 0

  #optionsOnly: listOption[] = []
  protected willUpdate(changedProperties: PropertyValues): void {
    if (changedProperties.has('options')) {
      this.#optionsOnly = this.options.flatMap((optionOrGroup) =>
        'options' in optionOrGroup ? optionOrGroup.options : optionOrGroup
      )
    }

    if (changedProperties.has('_activeIndex')) {
      defer(() => {
        const $active = this.shadowRoot?.getElementById(ID_ACTIVE_OPTION)
        if ($active) {
          const $scroll = $active.closest('one-ux-scroll')
          if ($scroll) {
            requestAnimationFrame(() => {
              scrollElementIntoView($scroll, $active)
            })
          }
        }
      })
    }
  }

  #scrollElement = createRef<OneUxScrollElement>()
  protected render() {
    const values = this.value
    const indexRef = {
      value: 0
    }
    return html`<one-ux-scroll class="one-ux-element--root" ${ref(this.#scrollElement)}>
      <div
        class="list-box"
        tabindex="0"
        role="listbox"
        aria-activedescendant=${ID_ACTIVE_OPTION}
        aria-required=${ifDefined(this.required)}
        aria-disabled=${ifDefined(this.disabled)}
        aria-multiselectable=${ifDefined(this.multiple)}
        aria-label=${ifDefined(this._labelContext.label || undefined)}
      >
        ${this.options.map((entry) => {
          if ('options' in entry) {
            return Group({
              disabled: this.disabled,
              group: entry,
              indexRef,
              activeIndex: this._activeIndex,
              multiple: this.multiple,
              values,
              onChange: this.#handleChange,
              onActivate: this.#handleActivation
            })
          } else {
            return Option({
              disabled: this.disabled,
              option: entry,
              index: indexRef.value++,
              activeIndex: this._activeIndex,
              multiple: this.multiple,
              values,
              onChange: this.#handleChange,
              onActivate: this.#handleActivation
            })
          }
        })}
      </div>
    </one-ux-scroll>`
  }

  firstUpdated() {
    this.#updateActiveIndex()
  }

  #updateActiveIndex() {
    const values = this.value || []
    if (!values.length || !this.#optionsOnly.length) {
      this._activeIndex = 0
      return
    }
    const firstSelectedIndex = this.#optionsOnly.findIndex((option) => values.includes(option.value))

    if (firstSelectedIndex !== -1) {
      this._activeIndex = firstSelectedIndex
    } else {
      this._activeIndex = 0
    }
  }

  updated(changed: PropertyValues) {
    if (changed.has('value') || changed.has('options')) {
      this._previewContext.updatePreview(this.#getPreview())
      if (this._activeIndex >= this.#optionsOnly.length) {
        this._activeIndex = this.#optionsOnly.length - 1
      }
    }
    if (changed.has('options')) {
      this.#updateActiveIndex()
    }
  }

  protected async getUpdateComplete() {
    const result = await super.getUpdateComplete()
    await this.#scrollElement.value?.updateComplete
    return result
  }

  #handleActivation = (index: number) => {
    this._activeIndex = index
  }

  #handleKeydown = (event: KeyboardEvent) => {
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    if (this.disabled) {
      return handled()
    }

    switch (event.code) {
      case keyCodes.DOWN:
        this._activeIndex = Math.min(this.#optionsOnly.length - 1, this._activeIndex + 1)
        return handled()
      case keyCodes.UP:
        this._activeIndex = Math.max(0, this._activeIndex - 1)
        return handled()
      case keyCodes.END:
        this._activeIndex = this.#optionsOnly.length - 1
        return handled()
      case keyCodes.HOME:
        this._activeIndex = 0
        return handled()
      case keyCodes.PAGEDOWN:
        this._activeIndex = Math.min(this.#optionsOnly.length - 1, this._activeIndex + 10)
        return handled()
      case keyCodes.PAGEUP:
        this._activeIndex = Math.max(0, this._activeIndex - 10)
        return handled()
      case keyCodes.RETURN:
      case keyCodes.SPACE:
        this.#handleChange(this.#optionsOnly[this._activeIndex])
        return handled()
    }

    const keyboardLetterKey = /^\S$/.test(event.key) ? event.key.toLowerCase() : null
    if (keyboardLetterKey !== null) {
      const shouldCycle = this.#optionsOnly[this._activeIndex].text[0].toLowerCase() === keyboardLetterKey
      const index = this.#optionsOnly.findIndex((option, index) => {
        const matches = option.text[0].toLowerCase() === keyboardLetterKey.toLowerCase()
        if (shouldCycle) {
          return matches && index > this._activeIndex
        }

        return matches
      })
      if (index !== -1) {
        this._activeIndex = index
        return handled()
      }
    }
  }

  #handleChange = (option: listOption) => {
    if (this.disabled || option.disabled) {
      return
    }

    const value = option.value
    if (this.multiple) {
      const values = this.value
      const newValue = values.includes(value) ? values.filter((x) => x !== value) : [...values, value]
      this._applyUserValue(newValue)
    } else {
      this._applyUserValue([value])
      this._popoutContext.closePopout()
    }
    this._previewContext.updatePreview(this.#getPreview())
    this.dispatchEvent(new Event('input'))
  }

  #getPreview() {
    const { translations } = getLanguage(this)

    if (this.value.length > 1) {
      return translations.selected.replace(/\$0/g, this.value.length.toString())
    }

    const selected = this.#optionsOnly.find((entry) => 'value' in entry && entry.value === this.value[0])
    return selected ? OptionContent(selected, { truncate: true }) : null
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-list': OneUxListElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-list': OneUxListElement
    }
  }
}
