import { html, nothing } from 'lit'
import { customElement, query, queryAssignedElements, state } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Placeholder } from '../../mixins/Placeholder.js'
import { Focusable } from '../../mixins/Focusable.js'
import { keyCodes } from '../../utils.js'
import { consume, provide } from '@lit/context'
import { labelContext, defaultLabelContext } from '../../contexts/LabelContext.js'
import { InternalElementStateChangedEvent } from '../../events/internal/InternalElementStateChangedEvent.js'
import { classMap } from 'lit/directives/class-map.js'
import { IPopoutContext, popoutContext } from '../../contexts/PopoutContext.js'
import { IPreviewContext, previewContext } from '../../contexts/PreviewContext.js'
import { Disabled } from '../../mixins/Disabled.js'

import type { OneUxPopoutElement } from '../../elements/one-ux-popout/OneUxPopoutElement.js'
import { TABBABLE_TARGETS_SELECTOR } from '../../utils/focusable.js'

const Styled = StyledFactory(style)

const BaseClass = Disabled(Placeholder(Focusable(Styled(OneUxElement))))

@customElement('one-ux-dropdown')
export class OneUxDropdownElement extends BaseClass {
  @consume({ context: labelContext, subscribe: true })
  private _labelContext = defaultLabelContext

  @provide({ context: previewContext })
  private _previewContext = {
    updatePreview: (preview: unknown) => {
      this._preview = preview
    }
  } as IPreviewContext

  @provide({ context: popoutContext })
  private _popoutContext = {
    closePopout: () => {
      this.close()
    }
  } as IPopoutContext

  constructor() {
    super()

    this.width = 'max'
    this.height = 'max'

    this.addEventListener(
      'blur',
      (event: FocusEvent) => {
        const $newFocus = event.relatedTarget as HTMLElement
        if (this.contains($newFocus) || this.shadowRoot?.contains($newFocus)) {
          return
        }
        this.#performClose()
      },
      { capture: true }
    )
    this.addEventListener('keydown', this.#handleKeydown)

    this.addEventListener(InternalElementStateChangedEvent.eventName, (e: Event) => {
      const event = e as InternalElementStateChangedEvent

      if (event.target === this) {
        return
      }
      const { property, value } = event.detail
      switch (property) {
        case 'disabled':
          event.stopImmediatePropagation()
          break
        case 'required':
          this._childComponentRequired = value as boolean
          break
        case 'empty':
          this._childComponentEmpty = value as boolean
          break
      }
    })
  }

  @state()
  private accessor _open = false

  @state()
  private accessor _childComponentRequired = false

  @state()
  private accessor _childComponentEmpty = false

  @state()
  private accessor _preview = '' as unknown

  render() {
    return html`<div class="one-ux-element--root" @click=${this.#toggleOpen}>
      <div
        class="field-inner"
        role="combobox"
        aria-placeholder=${ifDefined((this._childComponentEmpty && this.placeholder) || undefined)}
        aria-disabled=${this.disabled}
        aria-required=${!!this._childComponentRequired}
        aria-label=${ifDefined(this._labelContext.label || undefined)}
        aria-expanded=${this._open}
        tabindex=${this.disabled || this._open ? -1 : 0}
      >
        <div
          class=${classMap({
            'field-inner-content': true,
            empty: this._childComponentEmpty
          })}
        >
          <slot name="preview">${this._childComponentEmpty ? this.placeholder : this._preview}</slot>
        </div>
        <one-ux-icon class="field-icon" icon="toggle-down" aria-hidden="true" size="200"></one-ux-icon>
      </div>

      ${!this._open
        ? nothing
        : html`
            <one-ux-popout
              @click=${(e: Event) => e.stopPropagation()}
              reference="parent"
              indent="none"
              indent-top="normal"
              indent-bottom="normal"
              offset-reference="6"
            >
              <slot></slot>
            </one-ux-popout>
          `}
    </div>`
  }

  #handleKeydown = (event: KeyboardEvent) => {
    if (this.disabled) {
      return
    }
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    if (!this._open) {
      switch (event.code) {
        case keyCodes.SPACE:
        case keyCodes.UP:
        case keyCodes.DOWN:
        case keyCodes.RETURN:
          this.#toggleOpen()
          return handled()
      }
    } else {
      switch (event.code) {
        case keyCodes.ESCAPE:
          this.#toggleOpen()
          return handled()
      }
    }
  }

  @queryAssignedElements()
  private accessor _dropdownContent!: Array<HTMLElement>

  open() {
    if (this._open) {
      return
    }
    this.#toggleOpen()
  }

  close() {
    if (!this._open) {
      return
    }
    this.#toggleOpen()
  }

  @query('one-ux-popout')
  accessor _popoutElement!: OneUxPopoutElement

  #toggleOpen = async () => {
    if (this.disabled) {
      return
    }
    if (this._open) {
      // Fix for bug in chromium where mouse leave events are not triggered if you remove a DOM element below the mouse and the mouse afterwards is outside the ShadowDOM
      this.shadowRoot!.querySelector<HTMLElement>('.field-inner')!.focus()
      this._popoutElement.hidden = true
      requestAnimationFrame(() => {
        this.#performClose()
      })
    } else {
      this.dispatchEvent(new Event('open', { bubbles: false, composed: false }))
      requestAnimationFrame(async () => {
        this._open = true
        await this.updateComplete
        requestAnimationFrame(async () => {
          if (this._popoutElement && this._popoutElement.parentElement) {
            this._popoutElement.style.minWidth = this._popoutElement.parentElement.getBoundingClientRect().width + 'px'
          }

          const $child = this.#getFirstFocusableInPopout()
          if ($child) {
            if ('updateComplete' in $child) {
              await $child.updateComplete
            }
            $child.focus()
          }
        })
      })
    }
  }

  #performClose() {
    this._open = false
    this._popoutContext = { ...this._popoutContext }
    requestAnimationFrame(() => {
      this.dispatchEvent(new Event('close', { bubbles: false, composed: false }))
    })
  }

  #getFirstFocusableInPopout() {
    for (const $element of this._dropdownContent) {
      if ($element.matches(TABBABLE_TARGETS_SELECTOR)) {
        return $element
      }
      const $focusableChild = $element.querySelector<HTMLElement | SVGElement>(TABBABLE_TARGETS_SELECTOR)
      if ($focusableChild) {
        return $focusableChild
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-dropdown': OneUxDropdownElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-dropdown': OneUxDropdownElement
    }
  }
}
