import { OneUxElement } from '../../OneUxElement.js'
import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { PlacementController } from './PlacementController.js'
import { style } from './style.js'
import { Layout } from '../../mixins/Layout.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { Implicit } from '../../mixins/Implicit.js'

const Styled = StyledFactory(style)

const BaseClass = Implicit(Layout(Styled(OneUxElement)))

@customElement('one-ux-popout')
export class OneUxPopoutElement extends BaseClass {
  @property({ type: String })
  public accessor alignment: 'start' | 'center' | 'end' | 'cursor' = 'start'

  @property({ type: String })
  public accessor direction: 'horizontal' | 'vertical' = 'vertical'

  @property({ type: String })
  public accessor placement: 'before' | 'after' = 'after'

  @property({ type: String })
  public accessor reference: 'parent' | 'previous' | Element | null | undefined = 'parent'

  @property({ type: Number, attribute: 'reference-depth' })
  public accessor referenceDepth = 1

  @property({ type: Number, attribute: 'offset-reference' })
  public accessor offsetReference = 4

  @property({ type: Number, attribute: 'offset-alignment' })
  public accessor offsetAlignment = 0

  @property({ type: Boolean, attribute: 'prevent-overlap' })
  public accessor preventOverlap = false

  constructor() {
    super()
    this.indent = 'normal'
    new PlacementController(this)
  }

  protected render() {
    return html`
      <div class="one-ux-element--root">
        <div class="one-ux-element--content">
          <slot></slot>
        </div>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-popout': OneUxPopoutElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-popout': OneUxPopoutElement
    }
  }
}
